<template>
    <v-layout wrap>
        <v-flex class="d-flex justify-space-between mx-5" xs12>
            <span class="display-1 pb-5 font-weight-medium">{{
                $route.meta.name
            }}</span>
            <v-flex class="text-end">
                <v-btn @click="saveAndBack" class="mr-2" color="secondary"
                    >Salvează</v-btn
                >
                <v-btn
                    @click="save"
                    class="mr-2"
                    color="secondary"
                    v-if="!editMode"
                    width="300px"
                    >Salvează și rămăi pe pagină</v-btn
                >
                <v-btn @click="back" color="secondary">Înapoi</v-btn>
            </v-flex>
        </v-flex>
        <v-flex class="mx-5" xs12>
            <v-card class="pa-5">
                <v-layout>
                    <v-flex lg6 space-between xs12>
                        <v-flex>
                            <v-text-field
                                :error-messages="errors.name"
                                @input="delete errors.name"
                                label="Nume Înregistrare"
                                v-model="data.name"
                            ></v-text-field>
                        </v-flex>
                        <v-flex>
                            <v-text-field
                                :error-messages="errors.number"
                                @input="delete errors.number"
                                label="Număr de Ordine"
                                type="number"
                                v-model="data.number"
                            ></v-text-field>
                        </v-flex>

                        <v-flex>
                            <v-text-field
                                label="Eticheta Înregistrării"
                                v-model="tag"
                            ></v-text-field>
                        </v-flex>
                        <v-flex v-if="isHotarare">
                            <v-text-field
                                label="Cui a fost repartizat"
                                v-model="directed"
                            ></v-text-field>
                        </v-flex>
                        <v-flex d-flex v-if="isHotarare">
                            <v-text-field
                                class="pr-2"
                                label="Voturi Pentru"
                                type="number"
                                v-model="votes.for"
                            ></v-text-field>
                            <v-text-field
                                class="px-2"
                                label="Voturi Împotriva"
                                type="number"
                                v-model="votes.against"
                            ></v-text-field>
                            <v-text-field
                                class="pl-2"
                                label="Abțineri"
                                type="number"
                                v-model="votes.hold"
                            ></v-text-field>
                        </v-flex>
                    </v-flex>
                    <v-divider class="mx-3" vertical></v-divider>
                    <v-flex lg6 xs12>
                        <v-flex>
                            <v-file-input
                                :error-messages="errors.attachment"
                                label="Adaugă atașament intrării (opțional)"
                                v-model="data.attachment"
                            ></v-file-input>
                        </v-flex>
                        <v-layout class="text-center" wrap>
                            <v-flex>
                                <p>Dată Înregistrare</p>
                                <v-date-picker
                                    color="secondary"
                                    v-model="data.display_date"
                                ></v-date-picker>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
                <v-divider class="my-10" v-if="isHotarare"></v-divider>
                <v-layout mb-5 v-if="isHotarare" wrap>
                    <v-flex mb-5 xs12>
                        <span class="ma-0 headline">Documentație Proiect</span>
                    </v-flex>
                    <v-flex mb-5 xs12>
                        <vue-editor v-model="data.documentation"></vue-editor>
                    </v-flex>
                    <v-flex>
                        <v-file-input
                            :error-messages="errors.documentation_files"
                            chips
                            clearable
                            counter
                            label="Adaugă atașamente documentației (opțional)"
                            multiple
                            show-size
                            v-model="data.documentation_files"
                        ></v-file-input>
                    </v-flex>
                </v-layout>
                <v-card-actions class="my-10">
                    <v-spacer></v-spacer>
                    <v-btn @click="saveAndBack" color="secondary" width="300px"
                        >Salvează</v-btn
                    >
                    <v-btn
                        v-if="!editMode"
                        @click="save"
                        color="secondary"
                        width="300px"
                        >Salvează și rămăi pe pagină</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import displayErrors from "@/mixins/displayErrors";
import back from "@/mixins/back";
import editMode from "@/mixins/editMode";
import formatDate from "@/mixins/formatDate";
import { VueEditor } from "vue2-editor";

export default {
    name: "AddRecord",
    metaInfo: {
        title: "Înregistrare -"
    },
    components: {
        VueEditor
    },
    data: () => ({
        votes: {},
        tag: "",
        slug: "",
        resourceUri: "/records/show",
        directed: "",
        registryId: null
    }),
    mixins: [displayErrors, back, editMode, formatDate],
    computed: {
        isHotarare() {
            return this.registryId === 5;
        }
    },
    mounted() {
        const urlParams = new URLSearchParams(location.search);
        this.slug = urlParams.get("slug");
        this.registryId = parseInt(urlParams.get("id-registru"));
        if (!this.editMode) {
            this.getNextNumber();
        }
    },
    methods: {
        async getNextNumber() {
            const response = await this.axios.get(
                `/registries/number/${this.registryId}`
            );
            this.data = { ...this.data, number: parseInt(response.data) };
        },
        async update() {
            const formData = new FormData();
            Object.keys(this.data).forEach(key => {
                if (key === "documentation_files") {
                    this.data.documentation_files.forEach((file, i) => {
                        formData.append(key + i, file);
                    });
                } else {
                    formData.append(key, this.data[key]);
                }
            });
            formData.append(
                "meta",
                JSON.stringify({
                    votes: this.votes,
                    tag: this.tag,
                    directed: this.directed
                })
            );
            formData.append("_method", "PATCH");
            await this.axios.post(`/records/${this.id}`, formData, {
                headers: {
                    "Content-Type": `multipart/form-data;`
                }
            });
            this.success = true;
        },
        async save() {
            const formData = new FormData();
            Object.keys(this.data).forEach(key => {
                if (key === "documentation_files") {
                    this.data.documentation_files.forEach((file, i) => {
                        formData.append(key + i, file);
                    });
                } else {
                    formData.append(key, this.data[key]);
                }
            });
            formData.append(
                "meta",
                JSON.stringify({
                    votes: this.votes,
                    tag: this.tag,
                    directed: this.directed
                })
            );
            formData.append("registryId", this.registryId);
            await this.axios.post(`/records/${this.slug}`, formData, {
                headers: {
                    "Content-Type": `multipart/form-data;`
                }
            });
            this.data = {};
            this.votes = {};
            this.tag = "";
            this.getNextNumber();
            this.success = true;
        },
        async getData() {
            const response = await this.axios.get(
                `${this.resourceUri}/${this.id}`
            );
            if (response.data.display_date) {
                response.data.display_date = this.formatDate(
                    new Date(response.data.display_date),
                    "yyyy-MM-dd"
                );
            }
            this.data = response.data;
            if (this.data.meta) {
                if (JSON.parse(this.data.meta).tag) {
                    this.tag = JSON.parse(this.data.meta).tag;
                }

                if (JSON.parse(this.data.meta).votes) {
                    this.votes = JSON.parse(this.data.meta).votes;
                }

                if (JSON.parse(this.data.meta).directed) {
                    this.directed = JSON.parse(this.data.meta).directed;
                }
            }
        }
    }
};
</script>
